// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-clean-energy-js": () => import("./../../../src/pages/clean-energy.js" /* webpackChunkName: "component---src-pages-clean-energy-js" */),
  "component---src-pages-coffee-js": () => import("./../../../src/pages/coffee.js" /* webpackChunkName: "component---src-pages-coffee-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-craig-frames-privacy-policy-js": () => import("./../../../src/pages/craig-frames/privacy-policy.js" /* webpackChunkName: "component---src-pages-craig-frames-privacy-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-js": () => import("./../../../src/pages/insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */),
  "component---src-pages-law-js": () => import("./../../../src/pages/law.js" /* webpackChunkName: "component---src-pages-law-js" */),
  "component---src-pages-private-equity-js": () => import("./../../../src/pages/private-equity.js" /* webpackChunkName: "component---src-pages-private-equity-js" */)
}

